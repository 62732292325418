import './App.css';
import React, { lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
// Lazy load components
const Home = lazy(() => import('./components/Home/home'));
const VideosList = lazy(() => import('./components/Content/Videos/videosList'));
const VideoDetailView = lazy(() => import('./components/Content/Videos/videoDetailView'));
const GlobalQuiz = lazy(() => import('./components/Content/Quiz/GlobalQuiz'));
const FlashCards = lazy(() => import('./components/Content/FlashCards/FlashCards'));
const Spellings = lazy(() => import('./components/Content/Spellings/Spellings'));
const Tests = lazy(() => import('./components/Content/Tests/Tests'));
const TestsView = lazy(() => import('./components/Content/Tests/TestView'));
const QuizView = lazy(() => import('./components/Content/Quiz/QuizView'));
const FlashCardView = lazy(() => import('./components/Content/FlashCards/flashCardView'));
const SpellingsView = lazy(() => import('./components/Content/Spellings/SpellingsView'));
const SchoolQuiz = lazy(() => import('./components/Content/SchoolQuiz/SchoolQuiz'));
const MarketingBanners = lazy(() => import('./components/Content/MarketingBanners/marketingBanners'));
const Qams = lazy(() => import('./components/Content/QA/qams'));
const Grade = lazy(() => import('./components/Master/Grade/grade'));
const Concept = lazy(() => import('./components/Master/Concept/concept'));
const QuizAssets = lazy(() => import('./components/Master/QuizAssets/quizAssets'));
const QuestionAnsList = lazy(() => import('./components/Master/QuestionAnswersGlobal/questionAns'));
const QuestionAnsListUser = lazy(() => import('./components/Master/QuestionAnswersUsers/questionAnsUser'));
const QuestionAnsListgpt = lazy(() => import('./components/Master/QuestionAnswersGpt/questionAnsGpt'));
const SystemUsers = lazy(() => import('./components/Users/SystemUsers/systemUsers'));
const UsersList = lazy(() => import('./components/Users/Userslist/usersList'));
const Database = lazy(() => import('./components/Developer/Database/database'));
const Logs = lazy(() => import('./components/Developer/Log/logs'));
const TokenUsage = lazy(() => import('./components/Developer/TokenMonitor/tokenUsage'));
const Homepage = lazy(() => import('./components/Developer/Homepage/Homepage'));
const HomePageSections = lazy(() => import('./components/Developer/Homepage/HomePageSections'));
const Login = lazy(() => import('./components/login/login'));
const PageNotFound = lazy(() => import('./components/pageNotFound/pageNotFound'));
const ErrorComponent = lazy(() => import('./components/errorComponent/ErrorComponent'));
const MainView = lazy(() => import('./mainview'));

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <MainView />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/home", element: <Home /> },
      { path: "/content/videos", element: <VideosList /> },
      { path: "/content/videos/detailView/:videoid", element: <VideoDetailView /> },
      { path: "/content/globalQuiz", element: <GlobalQuiz /> },
      { path: "/content/quiz/view/:quizid", element: <QuizView /> },
      { path: "/content/schoolQuiz", element: <SchoolQuiz /> },
      { path: "/content/marketingBanners", element: <MarketingBanners /> },
      { path: "/content/spellings", element: <Spellings /> },
      { path: "/content/spellings/view/:fcid", element: <SpellingsView /> },
      { path: "/content/tests", element: <Tests /> },
      { path: "/content/tests/view/:fcid", element: <TestsView /> },
      { path: "/content/flashCards", element: <FlashCards /> },
      { path: "/content/flashCards/view/:fcid", element: <FlashCardView /> },
      { path: "/content/qams", element: <Qams /> },
      { path: "/masters/grade", element: <Grade /> },
      { path: "/masters/concept", element: <Concept /> },
      { path: "/masters/quizassets", element: <QuizAssets /> },
      { path: "/masters/questionanswersglobal", element: <QuestionAnsList /> },
      { path: "/masters/questionanswersusers", element: <QuestionAnsListUser /> },
      { path: "/masters/questionanswersgpt", element: <QuestionAnsListgpt /> },
      { path: "/users/usersList", element: <UsersList /> },
      { path: "/users/systemUsers", element: <SystemUsers /> },
      { path: "/developer/database", element: <Database /> },
      { path: "/developer/logs", element: <Logs /> },
      { path: "/developer/tokenUsage", element: <TokenUsage /> },
      { path: "/developer/homepage", element: <Homepage /> },
      { path: "/developer/homepage/:section_id", element: <HomePageSections /> },
    ],
    errorElement: <ErrorComponent />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

function App() {
  return (
    <>
      <div className="App"> 
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} /> 
        </Suspense>
      </div>
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
